/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-06-06 13:55:01
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-08-30 17:15:49
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\http\baseStationApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @description: 
 * @author: KR0176
 * @date: Do not edit
 */
/*
 * @description: 
 * @author: KR0176
 * @date: Do not edit
 */
/*
 * @description: 
 * @author: KR0176
 * @date: Do not edit
 */
// 基站接口
import axios from "./ajax.js"; // 导入ajax

/**
 * @Author KR0288
 * @Date 2022/05/18 10:48:46
 * @Description 添加基站
 */
export const addStation = data => {
  return axios({
    url: "/eprdms/device/station/add",
    method: "post",
    data: data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:48:52
 * @Description 删除基站
 */
export const deleteStation = data => {
  return axios({
    url: "/eprdms/device/station/delete",
    method: "post",
    data: data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:48:58
 * @Description 查询基站
 */
export const getStations = data => {
  return axios({
    url: "/eprdms/device/station/get",
    method: "get",
    params: data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:49:03
 * @Description 修改基站
 */
export const updateStation = data => {
  return axios({
    url: "/eprdms/device/station/update",
    method: "post",
    data: data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:49:12
 * @Description 充电基站控制
 */
export const controlStation = data => {
  return axios({
    url: "/eprdms/control/station/control",
    method: "post",
    data: data,
    headers: {
      robotMac: data.robotMac
      // userName: data.userName
    }
  });
};

/**
 * @Author KR0288
 * @Date 2022/08/30 17:13:17
 * @Description 查询控制记录（充电基站）
 */
 export const getControlRecordInBaseStation = data => {
  return axios({
    url: '/eprdms/device/control/record/get/station/id',
    method: 'post',
    data
  })
}