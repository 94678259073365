<template>
  <div class="device-table reboot-25kg">
    <div class="top-button">
      <el-button title="过滤重启模块" size="small" class="kr-normal-button top-item" @click="filterDeviceDialogVisible = true">条件筛选</el-button>
      <el-button title="添加重启模块" size="small" class="kr-normal-button top-item" @click="handleAdd">新增重启模块</el-button>
    </div>

    <el-table :data="restartModuleTableData" border style="width: 100%" height="79vh">
        <el-table-column label="序号" align="center" width="80">
          <template slot-scope="scope">
          <Icon name="bottomArrow" />
          <span>{{ scope['$index'] + 1 }}</span>
        </template>
        </el-table-column>
        <el-table-column prop="restartModuleName" label="模块名称" align="center"></el-table-column>
        <el-table-column prop="restartModuleMac" label="模块序列号" align="center"></el-table-column>
        <el-table-column prop="robotName" label="所在机器" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注内容" align="center" ></el-table-column>
        <el-table-column  label="重启记录" align="center" width="120">
          <template slot-scope="scope">
            <el-tag class="my-tag" size="small" @click="handleViewRestartRecords(scope.row)">查看记录</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="绑定邮箱" align="center" width="120">
           <template slot-scope="scope">
            <el-tag class="my-tag" size="small" @click="handleConfigRestartEmails(scope.row)">配置邮箱</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center"  width="120" >
        <template slot-scope="scope">
          <el-button title="编辑此模块" @click="handleEdit(scope.row)" type="text" class="table-btn"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
          <el-button title="删除此模块" @click="handleDelete(scope.row)" type="text" class="table-btn"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
        </template>
      </el-table-column>
      </el-table>
      <div class="pager-class">
        <el-pagination 
          @current-change="handlePageChange" 
          :current-page.sync="pageNumber" 
          :page-size="pageSize" 
          layout="total, prev, pager, next" 
          :total="total"
        ></el-pagination>
      </div>

      <!-- 过滤重启模块 -->
      <el-dialog class="my-dialog " title="筛选重启模块设备" :close-on-click-modal="false" :visible.sync="filterDeviceDialogVisible"  >
        <el-form :model="filterModuleForm"  ref="filterModuleFormRef">
        <el-form-item label="机器人" :label-width="formLabelWidth" prop="robotIds">
           <el-select v-model.trim="filterModuleForm.robotIds" multiple clearable filterable placeholder="请选择机器人">
            <el-option v-for="(item, index) in allRobotList" :key="index" :label="item.robotName" :value="item.robotId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模块名称" :label-width="formLabelWidth" prop="fuzzyName">
          <el-input v-model.trim="filterModuleForm.fuzzyName" auto-complete="off" placeholder="请输入模块名称关键字"></el-input>
        </el-form-item>
        <el-form-item label="模块序列号" :label-width="formLabelWidth" prop="fuzzyMac">
          <el-input v-model.trim="filterModuleForm.fuzzyMac" auto-complete="off" placeholder="请输入模块序列号关键字"></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="handleFilterDevice(false)">取 消</el-button>
        <el-button class="save-button" @click="handleFilterDevice(true)">应 用</el-button>
      </span>
      </el-dialog>

      <!-- 新增/修改重启模块 -->
      <el-dialog class="my-dialog " :title="this.isAddModule ? '新增重启模块' : '修改重启模块'" :close-on-click-modal="false" :visible.sync="addModuleDialogVisible" @close="handleCloseDialog('restartModuleFormRef')"  >
        <el-form :model="restartModuleForm"  ref="restartModuleFormRef" :rules="restartModuleFormRules">
        <el-form-item label="机器人" :label-width="formLabelWidth" prop="robotId">
           <el-select v-model.trim="restartModuleForm.robotId" placeholder="请选择机器人">
            <el-option v-for="(item, index) in unRelativeRobotList" :key="index" :label="item.robotName" :value="item.robotId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模块名称" :label-width="formLabelWidth" prop="restartModuleName">
          <el-input v-model.trim="restartModuleForm.restartModuleName" auto-complete="off" placeholder="请输入模块名称关键字"></el-input>
        </el-form-item>
        <el-form-item label="模块序列号" :label-width="formLabelWidth" prop="restartModuleMac">
          <el-input v-model.trim="restartModuleForm.restartModuleMac" auto-complete="off" placeholder="请输入模块序列号关键字"></el-input>
        </el-form-item>
         <el-form-item label="备注内容" :label-width="formLabelWidth" prop="remark">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model.trim="restartModuleForm.remark" auto-complete="off" placeholder="请输入备注内容"></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="addModuleDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="handleSaveModule('restartModuleFormRef')">保 存</el-button>
      </span>
      </el-dialog>

      <!-- 查看重启记录对话框 -->
      <el-dialog class="my-dialog2 restart-record-dialog" title="重启记录" :close-on-click-modal="false" width="80vw" top="10vh" :visible.sync="restartRecordDialogVisible" @close="handleCloseDialog('restartModuleRecordFormRef')"  >
        <el-form :model="restartModuleRecordForm" inline ref="restartModuleRecordFormRef" :rules="restartModuleRecordFormRules">
        <el-form-item label="起始时间" :label-width="formLabelWidth" prop="beginTime">
           <el-date-picker
            v-model="restartModuleRecordForm.beginTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" :label-width="formLabelWidth" prop="endTime">
           <el-date-picker
            v-model="restartModuleRecordForm.endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
         <el-form-item label="机器状态" :label-width="formLabelWidth" prop="robotStatus">
           <el-select v-model.trim="restartModuleRecordForm.robotStatus" multiple clearable filterable collapse-tags placeholder="请选择机器状态">
            <el-option v-for="(item, index) in robotStatusList" :key="index" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
        <el-button class="kr-normal-button" size="small" @click="handleSelectRestartRecord('restartModuleRecordFormRef')">查询</el-button>
      </el-form-item>
      </el-form>
      <div style="display: flex; flex-direction: row;">
      <el-table :data="restartRecordTableData" border style="width: 60%" height="70vh" empty-text="没有查询到匹配的重启记录！" @row-click="handleRowClick">
        <el-table-column label="序号" align="center" width="80">
          <template slot-scope="scope">
          <Icon name="bottomArrow" />
          <span>{{ scope['$index'] + 1 }}</span>
        </template>
        </el-table-column>
        <el-table-column prop="robotName" label="所在机器" align="center"></el-table-column>
        <el-table-column prop="restartModuleName" label="模块名称" align="center"></el-table-column>
        <el-table-column prop="robotStatus" label="机器状态" align="center">
            <template slot-scope="scope">
          <span>{{ robotStatusList.find(r => r.key.indexOf(scope.row.robotStatus)>-1).value}}</span>
        </template>
        </el-table-column>
        <el-table-column prop="onlineTime" label="记录时间" align="center"></el-table-column>
      </el-table>
      <el-descriptions style="width: 30%" :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            操作记录
          </template>
          {{ selectRestartRecord.restartRecord }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            操作者
          </template>
          {{ selectRestartRecord.userName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            客户端MAC
          </template>
          {{ selectRestartRecord.diskMac }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            网络信息
          </template>
          {{ selectRestartRecord.internetMsg }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            操作类型
          </template>
          {{ selectRestartRecord.operateType }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            操作时间
          </template>
          {{ selectRestartRecord.operateTime}}
        </el-descriptions-item>
      </el-descriptions>
      </div>
      </el-dialog>

      <!-- 更新重启模块的绑定邮箱 -->
      <el-dialog class="my-dialog module-email-dialog" title="配置邮箱" :close-on-click-modal="false"  :visible.sync="updateModuleEmailDialogVisible" @close="handleCloseDialog('restartModuleEmailFormRef')"  >
        <el-form :model="restartModuleEmailForm"  ref="restartModuleEmailFormRef" >
          <el-form-item v-for="(email, index) in restartModuleEmailForm.emailList" :key="index" :label="'邮箱'+ (index+1)" label-width="80px" :prop="'email_'+index" 
          :rules="restartModuleEmailFormRules">
            <el-input v-model.trim="email.value" auto-complete="off" placeholder="请输入邮箱地址">
                <el-button slot="append" class="el-icon-remove-outline button" title="移除此邮箱" @click="removeEmail(index)"></el-button>
                <el-button slot="append" v-if="index==restartModuleEmailForm.emailList.length-1" class="el-icon-circle-plus-outline button" title="新增一行" @click="addEmail"></el-button>
            </el-input>
          </el-form-item>
        </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="updateModuleEmailDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="handleUpdateModuleEmails('restartModuleEmailFormRef')">保 存</el-button>
      </span>
      </el-dialog>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";
import notEmptyParams from "@/utils/objectUtil.js";
import {
  get25kgRestartModule, 
  add25kgRestartModule, 
  update25kgRestartModule, 
  delete25kgRestartModule, 
  update25kgRestartModuleEmails, 
  get25kgRestartModuleRobots, 
  getRobotOnlineStatusDict, 
  get24kgRestartModuleRecord
} from "@/http/equipmentApi.js";
import moment from 'moment';
export default {
  name: "rebootSlightRobotTable",
  components: {
    Icon
  },

   data() {
     /*
      * @Date 2022/03/08 16:50:34
      * 校验邮箱地址格式是否正确
      */
    var validateEmail = (rule, value, callback) => {
      let index = parseInt(rule.field.slice(rule.field.indexOf("_")+1));
      let email = this.restartModuleEmailForm.emailList[index];
      if (!email.value) {
        return callback(new Error("此项邮箱地址为空！"));
      }
      var pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if(!pattern.test(email.value)){
        return callback(new Error('此项邮箱地址格式不正确！'));
      }

      return callback();
    };
    return {
      /*
       * @Date 2022/07/06 16:36:57
       * 表格信息
       */
      restartModuleTableData: [],
      selectModule: "",           // 当前选中的模块


      /*
       * @Date 2022/07/06 16:37:25
       * 分页信息
       */
      pageNumber: 1,
      pageSize: 10,
      total: 0,


      /*
       * @Date 2022/07/06 16:39:36
       * 过滤重启模块
       */
      filterDeviceDialogVisible: false,
      formLabelWidth: "5vw",            // label宽度
      allRobotList: [],                 // 可选择的机器人列表
      filterModuleForm: {
        robotIds: [],
        fuzzyMac: "",
        fuzzyName: ""
      },


      /*
       * @Date 2022/07/06 16:44:07
       * 新增 / 修改重启模块
       */
      addModuleDialogVisible: false,
      isAddModule: "true",
      unRelativeRobotList: [],        // 可选的机器人列表
      restartModuleForm: {
        robotId: "",
        restartModuleName: "",
        restartModuleMac: "",
        remark: ""
      },
      restartModuleFormRules: {
         robotId: [{ required: true, message: "请选择所属机器人", trigger: "blur" }],
         restartModuleName: [{ required: true, message: "请输入模块名称", trigger: "blur" }],
         restartModuleMac: [{ required: true, message: "请输入模块序列号", trigger: "blur" }],
      },


      /*
       * @Date 2022/07/06 16:47:53
       * 重启记录信息
       */
      restartRecordDialogVisible: false,
      robotStatusList: [],                // 可选的机器状态列表
      restartRecordTableData: [],         
      selectRestartRecord: "",            // 当前选择的重启记录
      restartModuleRecordForm: {
        restartModuleMac: "",
        robotStatus: [],
        beginTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
      },
      restartModuleRecordFormRules: {
        beginTime: [{required: true, message: "请选择起始时间", trigger: "blur"}],
        endTime: [{required: true, message: "请选择结束时间", trigger: "blur"}]
      },


      /*
       * @Date 2022/07/06 16:51:38
       * 更新邮箱信息
       */
      updateModuleEmailDialogVisible: false,
      restartModuleEmailForm: {
        emailList: [],
      },
      restartModuleEmailFormRules: {validator: validateEmail, trigger: "blur"}
    };
  },

  mounted() {
    /*
     * @Date 2022/03/08 10:28:23
     * 由于这个重启模块只针对于轻量型机器人，所以查询所有25K机器
     */
    get25kgRestartModuleRobots({isAll: true}).then(res => {
      if(res.code==="000000") {
        this.allRobotList = res.data;
      }
    })

    /*
     * @Date 2022/03/08 11:51:33
     * 查询重启模块数据
     */
    this.getRestartModules();

    /*
     * @Date 2022/03/08 15:01:05
     * 查询机器所有的重启状态
     */
    getRobotOnlineStatusDict().then(res => {
      this.robotStatusList = res.data;
    })
  },


  methods: {
    /*
     * @Date 2022/03/08 11:44:06
     * 查询重启模块数据
     */
    getRestartModules() {
        let params = notEmptyParams(this.filterModuleForm);
        params.pageNumber = this.pageNumber;
        params.pageSize = this.pageSize;

        get25kgRestartModule(params).then(res => {
          this.restartModuleTableData = res.rows;
          this.total = res.total;
        })
    },

    /*
     * @Date 2022/03/08 13:44:06
     * 过滤查询线路
     */
    handleFilterDevice(isFilter) {
      if(!isFilter) {
        this.$refs["filterModuleFormRef"].resetFields();
      }
      this.getRestartModules();
      this.filterDeviceDialogVisible = false;
    },

    /*
     * @Date 2022/03/08 13:25:38
     * 打开新增重启模块弹窗
     */
    handleAdd() {
       this.addModuleDialogVisible = true;
       this.isAddModule = true;
        get25kgRestartModuleRobots({isAll: false}).then(res => {
          if(res.code==="000000") {
            this.unRelativeRobotList = res.data;
          }
        })
    },

    /*
     * @Date 2022/03/08 13:26:59
     * 打开更新重启模块弹窗
     */
     handleEdit(row) {
       this.addModuleDialogVisible = true;
       this.isAddModule = false;
       this.$nextTick(() => {
         this.restartModuleForm =  JSON.parse(JSON.stringify(row));
         get25kgRestartModuleRobots({isAll: false}).then(res => {
          if(res.code==="000000") {
            this.unRelativeRobotList = res.data;
            let currentRobot = {robotId: row.robotId, robotName: row.robotName};
            this.unRelativeRobotList.push(currentRobot);
          }
        })
       })
    },

    /*
     * @Date 2022/03/08 13:24:50
     * 处理保存重启模块 
     */
    handleSaveModule(formRef) {
      this.$refs[formRef].validate(valid => {
        if(valid) {
          if(this.isAddModule) {
            add25kgRestartModule(this.restartModuleForm).then(res => {
              if(res.code==="000000") {
                this.$message.success(res.data);
              }else{
                this.$message.warning(res.data);
              }
               this.addModuleDialogVisible = false;
               this.getRestartModules();
            })
          }else {
            update25kgRestartModule(this.restartModuleForm).then(res => {
              if(res.code==="000000") {
                this.$message.success(res.data);
              }else{
                this.$message.warning(res.data);
              }
               this.addModuleDialogVisible = false;
               this.getRestartModules();
            })
          }
         
        }
      })
    },

    /*
     * @Date 2022/03/08 11:38:05
     * 处理关闭弹窗
     */
    handleCloseDialog(formRef) {
      this.$refs[formRef].resetFields();
      this.$refs[formRef].clearValidate();

      if(formRef.indexOf("restartModuleRecordFormRef")>-1) {
        this.selectRestartRecord = "";
        this.restartRecordTableData = [];
      }
    },

    /*
     * @Date 2022/03/08 13:55:16
     * 删除重启模块
     */
    handleDelete(row) {
      this.$confirm("此操作将永久删除重启模块, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(res => {
          delete25kgRestartModule({ id: row.id })
            .then(res => {
              if (res.code == '000000') {
                this.$message.success(res.mesg);
                this.getRestartModules();
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err, "取消操作");
        });
    },

    /*
    * @Date 2022/03/08 14:11:35
    * 查看重启模块操作记录
    */
    handleViewRestartRecords(row) {
       this.restartRecordDialogVisible = true;
       this.selectModule = row;
      
      setTimeout(() => {
        this.handleSelectRestartRecord("restartModuleRecordFormRef");
      }, 500);
       
    },

    /*
     * @Date 2022/03/08 14:28:22
     * 点击查询按钮，查询重启记录
     */
    handleSelectRestartRecord(formRef) {

      let begin = moment(this.restartModuleRecordForm.beginTime, "YYYY-MM-DD HH:mm:ss");
      let end = moment(this.restartModuleRecordForm.endTime, "YYYY-MM-DD HH:mm:ss");
      if(end.isBefore(begin)) {
        this.$message.warning("查询失败，选择的结束时间比开始时间要晚！");
        return;
      }

      this.$refs[formRef].validate(valid => {
        if(valid) {
          this.restartModuleRecordForm.restartModuleMac = this.selectModule.restartModuleMac;
          get24kgRestartModuleRecord(this.restartModuleRecordForm).then(res => {
            if(res.total==0) {
              this.$message.warning("当前条件没有查询到匹配的重启记录！");
            }

            this.restartRecordTableData = res.rows.map(r => {
              r.restartModuleName = this.selectModule.restartModuleName;
              r.robotName = this.selectModule.robotName;
              return r;
            });

            if(this.restartRecordTableData.length>0) {
              this.selectRestartRecord = this.restartRecordTableData[0].manualOption;
            }
            
          })
        }
      })
    },

    /*
     * @Date 2022/03/09 10:02:22
     * 处理单击操作记录行
     */
    handleRowClick(row, colum, event) {
      this.selectRestartRecord = row.manualOption;
    },

    /*
     * @Date 2022/03/08 15:05:51
     * 配置重启模块对应的邮箱
     */
    handleConfigRestartEmails(row) {
      this.updateModuleEmailDialogVisible = true;
      this.selectModule = row;
      this.restartModuleEmailForm.emailList = row.emailList.map(e => {return {value: e}});
      if(this.restartModuleEmailForm.emailList.length==0) {
        this.restartModuleEmailForm.emailList.push({value: ""})
      }
    },

    /*
     * @Date 2022/03/08 15:41:19
     * 动态移除邮箱表单中的邮箱
     */
    removeEmail(index) {
        if(index>-1 && this.restartModuleEmailForm.emailList.length>1) {
          this.restartModuleEmailForm.emailList.splice(index, 1);
        }else{
          this.$message.warning("至少应当保留一个邮箱地址！")
        }
    },

    /*
     * @Date 2022/03/08 15:41:40
     *  动态新增邮箱表单中的邮箱
     */
    addEmail() {
      let tmp = {value: ""};
      this.restartModuleEmailForm.emailList.push(tmp);
    },

    /*
     * @Date 2022/03/08 15:59:33
     * 处理更新重启模块对应的邮件列表
     */
    handleUpdateModuleEmails(formRef) {
      
      console.log('ref: ' + formRef);
      this.$refs[formRef].validate((valid) => {
           if(valid) {
          let emailList = this.restartModuleEmailForm.emailList.map(e => {
            return e.value;
          });

          let params = {restartModuleMac: this.selectModule.restartModuleMac, emailList};

          update25kgRestartModuleEmails(params).then(res => {
            if(res.code==="000000") {
              this.$message.success(res.data);
              this.updateModuleEmailDialogVisible = false;
              this.getRestartModules();
            }else {
              this.$message.warnning(res.data);
            }
          })
        }
       
      })    
    },

    /**
     * @Author KR0288
     * @Date 2022/08/25 20:22:54
     * @Description 分页切换
     */
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getRestartModules();
    }
  } 
}
</script>

<style scoped lang="scss">

</style>