<template>
  <div class="device-table">
    <div class="top-button">

      <div class="left">
        <span style="color: #FFF; margin-right: 1rem">巡检线路</span>
        <el-select class="normal-select" style="width: 10vw" :disabled="!isAdd" v-model.trim="lineId" placeholder="请选择基站所属线路" @change="getStationsTableData(lineId)">
          <el-option v-for="(line, index) in allLinesOption" :key="index" :label="line.lineName" :value="line.lineId"></el-option>
        </el-select>
      </div>

      <div class="right">
        <!-- <el-input v-model="searchKey" placeholder="筛选充电基站" clearable class="normal-input top-item top-input"></el-input> -->
        <el-button title="添加充电基站" size="small" class="kr-normal-button top-item" @click="handleAdd">新增充电基站</el-button>
      </div>

    </div>


    <el-table :data="stationTableData" border style="width: 100%" height="78vh">
       <el-table-column prop="date" label="序号" align="center" width="80">
        <template slot-scope="scope">
          <Icon name="bottomArrow" />
          <span>{{ scope['$index'] + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="towerIndex" label="塔杆号" align="center" width="100px"></el-table-column>
      <el-table-column prop="towerName" label="塔杆名称" align="center"></el-table-column>
      <el-table-column prop="stationName" label="基站名称" align="center" width="120px"></el-table-column>
      <el-table-column prop="onlineStatus" label="基站状态" align="center"></el-table-column>
      <el-table-column prop="robotMac" label="机器人MAC" align="center" width="150px"></el-table-column>
      <el-table-column prop="stationImei" label="基站序列号" align="center" width="180px"></el-table-column>
      <el-table-column prop="robotCharge" label="机器人充电开关" align="center" width="120px">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.robotCharge" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0" @change="Switch('robot', scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="batteryCharge" label="蓄电池充电开关" align="center" width="120px">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.batteryCharge" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0" @change="Switch('batter', scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="solarCharge" label="太阳能板电流开关" align="center" width="120px">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.solarCharge" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0" @change="Switch('solar', scope.row)"></el-switch>
  
        </template>
      </el-table-column>
      <el-table-column prop="chargeCurrent" label="充电电流" align="center" width="80px">
        <template slot-scope="scope">
          <span :class="scope.row.chargeCurrent > 15 ? 'redColor' : ''">{{ scope.row.chargeCurrent }} A</span>
        </template>
      </el-table-column>
      <el-table-column prop="batteryVoltage" label="蓄电池电压" align="center" width="90px">
        <template slot-scope="scope">
          <span :class="scope.row.batteryVoltage > 60 ? 'redColor' : ''">{{ scope.row.batteryVoltage }} V</span>
        </template>
      </el-table-column>
      <el-table-column prop="solarCurrent" label="太阳能板电流" align="center" width="90px">
        <template slot-scope="scope">
          <span :class="scope.row.solarCurrent > 15 ? 'redColor' : ''">{{ scope.row.solarCurrent }} A</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
         <el-button title="控制记录" @click="clickRecord(scope.row)" type="text" class="table-btn"><i class="el-icon-more" style="color:#fff;font-size:1.5vh;"></i></el-button>
          <el-button title="编辑基站信息" @click="handleEdit(scope.row)" type="text" class="table-btn"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
          <el-button title="删除基站" @click="deleteStation(scope.row)" type="text" class="table-btn"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-class">
      <el-pagination 
        @current-change="handlePageChange" 
        :current-page.sync="pageNumber" 
        :page-size="pageSize" 
        layout="total, prev, pager, next" 
        :total="total"
      ></el-pagination>
    </div>


    <!-- 添加基站 -->
    <el-dialog class="my-dialog" :title="stationDialogTitle" :close-on-click-modal="false" :visible.sync="baseStationDialogVisible" @closed="closedDialog('stationForm')">
      <el-form :model="stationForm" :rules="stationFormRules" ref="stationForm">
    
        <el-form-item label="所属线路" :label-width="formLabelWidth" prop="lineId">
          <el-select :disabled="!isAdd" v-model.trim="stationForm.lineId" placeholder="请选择基站所属线路" @change="getTowerByLine">
            <el-option v-for="(line, index) in allLinesOption" :key="index" :label="line.lineName" :value="line.lineId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属杆塔" :label-width="formLabelWidth" prop="towerId">
          <el-select :disabled="!isAdd" v-model.trim="stationForm.towerId" placeholder="请选择所属杆塔">
            <el-option v-for="(item, index) in towerIndexOption" :key="index" :label="item.towerName" :value="item.towerId"></el-option>
          </el-select>
        </el-form-item>
          <el-form-item label="基站名称" :label-width="formLabelWidth" prop="stationName">
          <el-input v-model.trim="stationForm.stationName" auto-complete="off" placeholder="请输入基站名称"></el-input>
        </el-form-item>
        <el-form-item label="设备序列号" :label-width="formLabelWidth" prop="stationImei">
          <el-input v-model.trim="stationForm.stationImei" auto-complete="off" placeholder="请输入设备序列号"></el-input>
        </el-form-item>
        <el-form-item label="设备MAC" :label-width="formLabelWidth" prop="robotMac">
          <el-input v-model.trim="stationForm.robotMac" auto-complete="off" placeholder="请输入设备地址"></el-input>
        </el-form-item>
        <el-form-item label="备 注" :label-width="formLabelWidth" prop="serialNumber">
          <el-input v-model.trim="stationForm.remark" auto-complete="off" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="baseStationDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="save">确 定</el-button>
      </span>
    </el-dialog>

    <!--控制记录-->
    <el-dialog class="my-dialog2 restart-record-dialog" title="控制记录" :close-on-click-modal="false" width="80vw" top="10vh" :visible.sync="controlRecordDialogVisible" @close="handleCloseDialog('controlRecordFormRef')"  >
      <el-form :model="controlRecordForm" inline ref="controlRecordFormRef" :rules="controlRecordFormRules">
        <el-form-item label="起始时间" :label-width="formLabelWidth" prop="beginTime">
           <el-date-picker
            v-model="controlRecordForm.beginTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" :label-width="formLabelWidth" prop="endTime">
           <el-date-picker
            v-model="controlRecordForm.endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
         <el-form-item label="结果" :label-width="formLabelWidth" prop="type">
           <el-select v-model.trim="controlRecordForm.type" clearable filterable collapse-tags placeholder="请选择结果">
            <el-option v-for="(item, index) in resultList" :key="index" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
        <el-button class="kr-normal-button" size="small" @click="clickCheckBtn">查询</el-button>
      </el-form-item>
      </el-form>

      <div class="dialog-content">
        <el-table :data="controlRecordTableData" border class="dialog-table" empty-text="没有查询到匹配的重启记录！" @row-click="handleRowClick">
          <el-table-column label="序号" align="center" width="80">
            <template slot-scope="scope">
            <Icon name="bottomArrow" />
            <span>{{ scope['$index'] + 1 }}</span>
          </template>
          </el-table-column>
          <el-table-column prop="towerIndex" label="杆塔号" align="center"></el-table-column>
          <el-table-column prop="towerName" label="杆塔名称" align="center"></el-table-column>
          <el-table-column prop="stationName" label="基站名称" align="center"></el-table-column>
          <el-table-column prop="stationImei" label="序列号" align="center"></el-table-column>
          <el-table-column prop="dataPointName" label="开关名称" align="center"></el-table-column>
          <el-table-column prop="actionValue" label="操作" align="center"></el-table-column>
          <el-table-column prop="resultDesc" label="结果" align="center"></el-table-column>
          <!-- <el-table-column prop="robotStatus" label="机器状态" align="center">
              <template slot-scope="scope">
            <span>{{ robotStatusList.find(r => r.key.indexOf(scope.row.robotStatus)>-1).value}}</span>
          </template>
          </el-table-column> -->
          <el-table-column prop="createTime" label="记录时间" align="center"></el-table-column>
        </el-table>
        <div class="pager-class">
          <el-pagination 
            @current-change="handleRecordPageChange" 
            :current-page.sync="pageNumber" 
            :page-size="pageSize" 
            layout="total, prev, pager, next" 
            :total="total"
          ></el-pagination>
        </div>
      <!-- <el-descriptions style="width: 30%" :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            操作记录
          </template>
          {{ selectControlRecord.restartRecord }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            操作者
          </template>
          {{ selectControlRecord.userName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            客户端MAC
          </template>
          {{ selectControlRecord.diskMac }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            网络信息
          </template>
          {{ selectControlRecord.internetMsg }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            操作类型
          </template>
          {{ selectControlRecord.operateType }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            操作时间
          </template>
          {{ selectControlRecord.operateTime}}
        </el-descriptions-item>
      </el-descriptions> -->
      </div>
      </el-dialog>
  </div>
</template>
<script>
import Icon from "@/components/Icon.vue";
import { getTowerNameList } from "@/http/towerListDetailsApi.js";
import { getLineNames } from "@/http/homeApi.js";
import { getStations, addStation, deleteStation, updateStation, controlStation, getControlRecordInBaseStation } from "@/http/baseStationApi.js";
import { getRobotOnlineStatusDict } from '@/http/equipmentApi.js'
import { mapState } from "vuex";
import moment from 'moment';
// import qs from "qs";
export default {
  name: "stationTable",
    components: {
    Icon
  },
  data() {
    return {
      /*
       * @Date 2022/07/06 15:06:20
       * 表格信息
       */
      lineId: "",             // 当前选择的线路
      allLinesOption: [],     
      stationTableData: [],   // 基站表格


      /*
       * @Date 2022/07/06 15:08:23
       * 新增 / 修改基站信息
       */
      isAdd: true,              
      stationDialogTitle: "添加基站",
      baseStationDialogVisible: false,
      formLabelWidth: "5vw",
      towerIndexOption: [],           // 表单可选塔杆
      // robotStatusList: [],
      stationForm: {
        batteryChargePeriod: 0,
        batteryCurrentPeriod: 0,
        id: 0,
        lineId: "",
        robotChargePeriod: 0,
        robotCurrentPeriod: 0,
        robotMac: "",
        solarChargePeriod: 0,
        solarCurrentPeriod: 0,
        stationImei: "",
        stationName: "",
        timeout: 0,
        towerId: "",
        remark: ""
      },
      stationFormRules: {
        stationName: [{ required: true, message: "请输入基站名称", trigger: "blur" }],
        lineId: [{ required: true, message: "请选择所属线路", trigger: "blur" }],
        towerId: [{ required: true, message: "请选择所属所属杆塔", trigger: "blur" }],
        stationImei: [{ required: true, message: "请输入设备序列号", trigger: "blur" }],
        robotMac: [{ required: true, message: "请输入设备MAC", trigger: "blur" }]
      },


      /*
       * @Date 2022/07/06 15:16:15
       * 控制记录信息
       */
      controlRecordDialogVisible : false,
      controlRecordTableData: [],    
      selectControlRecord: "",            // 当前选择的记录 
      lastParams: null,                   // 上一次保存的参数
      isOnSearch: false,                  // 是否处于搜索模式
      resultList: [{
        value: '成功',
        key: 1
      },{
        value: '失败',
        key: 0
      }],
      controlRecordForm: {
        type: "",
        beginTime: null,
        endTime: null,
      },
      controlRecordFormRules: {
      },


      /*
       * @Date 2022/07/06 15:21:22
       * 分页
       */
      pageNumber: 1,
      pageSize: 10,
      total: 0, 
    };
  },

  computed: {
    ...mapState(["userMessage", "baseStationMessage"])
  },

  watch: {
    baseStationMessage: {
      handler(val) {
        // 改变表格中对应的数据
        for (let i = 0; i < this.stationTableData.length; i++) {
          const item = this.stationTableData[i];
               console.log("item",item);
          for (let j = 0; j < val.length; j++) {
            const newItem = val[j];
            if (item.robotMac == newItem.robotMac && item.towerIndex == newItem.num) {
              //mac相同并且所属塔号相同
              item.batteryVoltage = newItem.battery_voltage;
              item.chargeCurrent = newItem.charge_current;
              item.solarCurrent = newItem.solar_current;
              item.robotCharge = newItem.robot_charge;
              item.solarCharge = newItem.solar_charge;
              item.batteryCharge = newItem.battery_charge;
              console.log("数据",this.stationTableData);
            }
          }
        }
      },
      deep: true
    }
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/07/06 15:03:02
     * @Description 获取全部线路,使用第一条线路作为初始线路获取基站信息
     */
     getAllLineList() {
      getLineNames().then(res => {
        this.allLinesOption = res.data;
        this.lineId = res.data[0].lineId;
        this.getStationsTableData(this.lineId);
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:40:48
     * @Description 获取基站表格数据
     */
    getStationsTableData(lineId) {
      let params = { lineId: lineId };
      getStations(params).then(res => {
        console.log(res);
        if(res.data.length==0) {
          this.$message.warning("当前线路没有查询到充电基站！");
          this.stationTableData = [];
          return;
        }
        res.data.forEach(station => {
          //初始化开关和电流/电压
          station.robotCharge = "0";
          station.batteryCharge = "0";
          station.solarCharge = "0";
          station.chargeCurrent = "0.00";
          station.solarCurrent = "0.00";
          station.batteryVoltage = "0.00";
        });
        let quotient = res.data.length / this.pageSize;
        this.total = res.data.length;
        if(this.pageNumber > quotient) {
          this.stationTableData = res.data.slice((this.pageNumber - 1) * this.pageSize);
        } else {
          this.stationTableData = res.data.slice((this.pageNumber - 1) * this.pageSize, (this.pageNumber) * this.pageSize)
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:49:23
     * @Description 切换机器人某些开关
     */
    Switch(type, row) {
      let params = {
        stationId: row.id,
        control: "",
        name: type,
        num: row.towerIndex,
        robotMac: row.robotMac,
        userName: this.userMessage.userName
      };
      switch (type) {
        case "batter":
          params.control = row.batteryCharge;
          break;
        case "solar":
          params.control = row.solarCharge;
          break;
        case "robot":
          params.control = row.robotCharge;
          break;
      }
      controlStation(params)
        .then(res => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.warning(res.mesg);
            row.batteryCharge = "0";
            row.solarCharge = "0";
            row.robotCharge = "0";
          }
        })
        .catch(err => {
          console.log(err);
          if (err.status == 500) {
            this.$message.warning(err.data.data);
          }
        });
      console.log(params);
    },
  
    /**
     * @Author KR0288
     * @Date 2022/07/06 15:35:47
     * @Description 新增基站
     */
    handleAdd() {
      this.isAdd = true;
      this.stationForm.lineId = this.lineId;
      this.stationDialogTitle = "新增基站信息";
      this.showDialog();
      this.getTowerByLine(this.lineId);
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:36:38
     * @Description 编辑按钮
     */
    handleEdit(row) {
      this.stationForm = JSON.parse(JSON.stringify(row));
      this.getTowerByLine(this.lineId);
      this.stationForm.lineId = this.lineId;
      this.stationForm.towerId = row.towerId;
      console.log(this.stationForm);
      this.isAdd = false;
      this.stationDialogTitle = "修改基站信息";
      this.showDialog();
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:33:26
     * @Description 打开对话框
     */
    showDialog() {
      this.baseStationDialogVisible = true;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:28:26
     * @Description 根据线路获取塔杆
     */
    getTowerByLine(lineId) {
      this.stationForm.towerId = "";
      getTowerNameList({ lineId}).then(res => {
        this.towerIndexOption = res.data;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:37:34
     * @Description 对话框保存按钮
     */
    save() {
      if (this.isAdd) {
        this.addStationBtn();
      } else {
        this.updateStationBtn();
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:37:52
     * @Description 添加基站
     */
    addStationBtn() {
      let params = this.stationForm;
      this.$refs.stationForm.validate(valid => {
        if (valid) {
          addStation(params)
            .then(res => {
              if (res.code == "000000") {
                this.$message.success(res.data ? res.data : res.mesg);
                this.baseStationDialogVisible = false;
                this.getStationsTableData(this.lineId);
              } else {
                this.$message.warning(res.data ? res.data : res.mesg);
              }
            })
            .catch(err => {
              if (err.status == 500) {
                this.$message.warning(err.data.data);
              }
            });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:38:32
     * @Description 更新基站
     */
    updateStationBtn() {
      let params = this.stationForm;
      this.$refs.stationForm.validate(valid => {
        if (valid) {
          updateStation(params).then(res => {
            if (res.code == "000000") {
              this.$message.success(res.data ? res.data : res.mesg);
              this.baseStationDialogVisible = false;
              this.getStationsTableData(this.lineId);
            } else {
              this.$message.error(res.data ? res.data : res.mesg);
            }
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:40:11
     * @Description 删除基站
     */
    deleteStation(row) {
      this.$confirm("此操作将永久删除基站, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(res => {
          console.log(res);
          deleteStation({ id: row.id })
            .then(res => {
              if (res.code == "000000") {
                this.getStationsTableData(this.lineId);
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err, "取消操作");
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:44:38
     * @Description 关闭对话框时回调
     */
    closedDialog(formName) {
      this.$refs[formName].clearValidate();
      this.isAdd = true;
      this.stationDialogTitle = "添加基站";
      this.stationForm = {
        towerId: "",
        lineId: "",
        robotMac: "",
        serialNumber: ""
      };
    },
    
    /**
     * @Author KR0288
     * @Date 2022/07/06 15:42:33
     * @Description 打开控制记录对话框
     */
    clickRecord(row){
      this.controlRecordDialogVisible = true;
      this.pageNumber = 1;
      this.selectModule = row;
      setTimeout(() => {
        this.handleSelectControlRecord();
      }, 500);
    },

    /**
     * @Author KR0288
     * @Date 2022/08/30 19:03:40
     * @Description 点击查询按钮
     */
    clickCheckBtn() {
      this.pageNumber = 1;
      this.lastParams = JSON.parse(JSON.stringify(this.controlRecordForm));
      this.isOnSearch = false;
      console.log(Object.keys(this.controlRecordForm));
      Object.keys(this.controlRecordForm).forEach(key => {
        if(this.controlRecordForm[key] !== "" && this.controlRecordForm[key] !== null && this.controlRecordForm[key] !== undefined) {
          this.isOnSearch = true;
        }
        console.log(this.controlRecordForm[key] !== "",this.controlRecordForm[key] !== null,this.controlRecordForm[key] !== undefined);
      })
      this.handleSelectControlRecord();
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:46:59
     * @Description 查询控制记录表格数据
     */
    handleSelectControlRecord(){
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        stationId: String(this.selectModule.baseStationId),
      };
      if(this.isOnSearch) {
        Object.keys(this.lastParams).forEach(key => {
          if(this.lastParams[key] !== "" && this.lastParams[key] !== null && this.lastParams[key] !== undefined) {
            params[key] = this.lastParams[key];
          }
        })
      } else {
        Object.keys(this.controlRecordForm).forEach(key => {
          if(this.controlRecordForm[key] !== "" && this.controlRecordForm[key] !== null && this.controlRecordForm[key] !== undefined) {
            params[key] = this.controlRecordForm[key];
          }
        })
      }

      getControlRecordInBaseStation(params).then(res => {
        this.controlRecordTableData = res.rows;
        this.total = res.total;
        if(res.rows.length === 0) {
          this.$message.success(res.result);
        }
      }).catch(err => {
        console.log(err);
      })

    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:47:42
     * @Description 表格行点击
     */
    handleRowClick(row,col,event){
     this.selectControlRecord = row.manualOption;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:50:26
     * @Description 关闭控制记录对话框
     */
    handleCloseDialog(ref){
      this.controlRecordDialogVisible = false;
      this.selectControlRecord = "";
      this.controlRecordTableData = [];
      this.pageNumber = 1;
      this.isOnSearch = false;
      this.lastParams = null;
      this.getStationsTableData(this.lineId);
    },

    /**
     * @Author KR0288
     * @Date 2022/08/30 19:13:24
     * @Description 切换控制记录分页
     */
    handleRecordPageChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.handleSelectControlRecord();
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 15:50:47
     * @Description 点击分页页码
     */
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getStationsTableData(this.lineId);
    }
  },
  
  mounted() {
    this.getAllLineList();
    /*
     * @Date 2022/03/08 15:01:05
     * 查询机器所有的重启状态
     */
    // getRobotOnlineStatusDict().then(res => {
    //   this.robotStatusList = res.data;
    // })
  }
};
</script>
<style lang="scss" scoped>
.redColor {
  color: #ff0;
}
ul.line-list,
.line-list li {
  margin: 0;
  padding: 0;
}
ul.line-list {
  width: 180px;
  margin-right: 20px;
}
.line-list li {
  font-size: 12px;
  height: 4vh;
  line-height: 4vh;
  list-style: none;
  color: #fff;
  cursor: pointer;
  border-bottom:0.1vh solid #36e6bd60; ;
}
.line-list li:hover {
  color: #00ffbf;
}
li.line-actived {
  color: #028f6d;
}
.pager-class {
  display: flex;
  justify-content: center;
}

.dialog-content {
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 70vh;

  .dialog-table {
    flex: 1;
    overflow: auto;
    width: 100%;
  }

  .pager-class {
    height: .1667rem;
  }
}
</style>
