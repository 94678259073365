<!--
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-05-26 15:47:06
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-05-26 16:27:27
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\components\equipmentMenu\warnNoticeTable\resetPasswordDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="resetContainer">
        <el-form :model="contactsForm" :rules="formRules" ref="contactsForm" label-width="80px" class="contactsForm" :popper-append-to-body="false">
            <el-form-item label="用户名" prop="username">
                <el-input v-model="contactsForm.username"></el-input>
            </el-form-item>
            <el-form-item label="原密码" prop="oldPwd">
                <el-input v-model="contactsForm.oldPwd" type="password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd">
                <el-input v-model="contactsForm.newPwd" type="password"></el-input>
            </el-form-item>
            <el-form-item class="btnGroup">
                <el-button class="kr-normal-button" @click="cancel">取消</el-button>
                <el-button class="kr-normal-button" @click="submit">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {
    updatePassword
} from '../../../http/equipmentApi'
export default {
    props: {
        /*
         * @Date 2022/05/26 11:36:23
         * 当前选择的表格行
         */
        row: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            // 表单信息
            contactsForm: {         // 表单值
                id: '',
                username: '',
                newPwd: '',
                oldPwd: '',
            },       
            formRules: {            // 表单校验规则
                username: [{
                    required: true, 
                    message: "用户名不能为空", 
                    trigger: "blur" 
                }],              
                oldPwd: [{
                    required: true, 
                    message: "原密码不能为空", 
                    trigger: "blur" 
                }],
                newPwd: [{     
                    required: true, 
                    message: "新密码不能为空", 
                    trigger: "blur" 
                }],
            }
        }
    },

    methods: {
        /**
         * @Author KR0288
         * @Date 2022/05/26 16:00:46
         * @Description 初始化表单
         */
        initForm() {
            this.contactsForm.username = this.row.username;
            this.contactsForm.id = this.row.id
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 10:22:37
         * @Description 取消重置
         */
        cancel() {
            this.$emit('close');
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 10:28:56
         * @Description 提交新密码
         */
        submit() {
            updatePassword(this.contactsForm).then(res => {
                if(res.code == '000000') {
                    this.$message({
                        type: 'success',
                        message: res.mesg,
                    })
                    this.$emit('close');
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data
                    })
                }
            }).catch(err => {
                this.$message({
                    type: 'error',
                    message: err
                })
            })   
        },
    },

    watch: {
        row: function(newValue)  {
            this.initForm();
        }
    },

    mounted() {
        this.initForm();
    }
}
</script>

<style>

</style>