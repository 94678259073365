/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-04-12 11:33:12
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-08-26 13:24:14
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\http\rebootModelApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 重启模块接口
import axios from "./ajax.js"; // 导入ajax

/**
 * @Author KR0288
 * @Date 2022/05/18 14:18:41
 * @Description 新增组网重启模块
 */
export const addRebootModel = data => {
  return axios({
    url: "/eprdms/device/reboot/add",
    method: "post",
    data: data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:18:50
 * @Description 删除组网重启模块
 */
export const deleteRebootModel = data => {
  return axios({
    url: "/eprdms/device/reboot/delete",
    method: "post",
    data: data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:18:58
 * @Description 查询组网重启模块
 */
export const getRebootModel = data => {
  return axios({
    url: "/eprdms/device/reboot/get",
    method: "get",
    params: data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:19:06
 * @Description 更新组网重启模块
 */
export const updateRebootModel = data => {
  return axios({
    url: "/eprdms/device/reboot/update",
    method: "post",
    data: data
  });
}

  /**
 * 查询重启记录
 */
export const getControlRecord = data => {
  return axios({
    url: "/eprdms/device/control/record/get/by/id",
    method: "post",
    data: data
  });
};
