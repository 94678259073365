import { render, staticRenderFns } from "./warnNoticeTable.vue?vue&type=template&id=969ecc50&scoped=true&"
import script from "./warnNoticeTable.vue?vue&type=script&lang=js&"
export * from "./warnNoticeTable.vue?vue&type=script&lang=js&"
import style0 from "./warnNoticeTable.vue?vue&type=style&index=0&id=969ecc50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "969ecc50",
  null
  
)

export default component.exports