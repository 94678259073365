<template>
  <div class="device-table reboot-25kg">
    <div class="top-button">
      <el-button title="过滤气象基站" size="small" class="kr-normal-button top-item" @click="filterWeatherStationDialogVisible = true">条件过滤</el-button>
      <el-button title="添加气象基站" size="small" class="kr-normal-button top-item" @click="handleAddWeatherStation">添加气象基站</el-button>
    </div>

    <el-table :data="weatherStationTableData" border style="width: 100%" height="79vh" empty-text="没有查询到匹配的气象基站！">
         <el-table-column label="序号" align="center" width="80">
          <template slot-scope="scope">
          <Icon name="bottomArrow" />
          <span>{{ scope['$index'] + 1 }}</span>
        </template>
         </el-table-column>
        <el-table-column prop="lineName" label="所属线路" align="center"></el-table-column>
        <el-table-column prop="towerName" label="安装杆塔" align="center"></el-table-column>
        <el-table-column prop="weatherStationName" label="基站名称" align="center"></el-table-column>
        <el-table-column prop="weatherStationMac" label="基站MAC" align="center"></el-table-column>
        <el-table-column prop="installDate" label="安装时间" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注内容" align="center"></el-table-column>
        <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button title="编辑此模块" @click="handleEdit(scope.row)" type="text" class="table-btn"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
          <el-button title="删除此模块" @click="handleDelete(scope.row)" type="text" class="table-btn"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-class">
      <el-pagination 
        @current-change="handlePageChange(pageNumber)" 
        :current-page.sync="pageNumber" 
        :page-size="pageSize" 
        layout="total, prev, pager, next" 
        :total="total"
        ></el-pagination>
    </div>

    <!-- 过滤气象基站对话框 -->
    <el-dialog class="my-dialog " title="筛选气象基站设备" :close-on-click-modal="false" :visible.sync="filterWeatherStationDialogVisible"  >
      <el-form :model="filterWeatherStaionForm"  ref="filterWeatherStaionFormRef">
      <el-form-item label="所属线路" :label-width="formLabelWidth" prop="lineId">
          <el-select v-model.trim="filterWeatherStaionForm.lineId" clearable filterable placeholder="请选择所属线路" @change="handleLineChange(filterWeatherStaionForm.lineId)">
          <el-option v-for="(item, index) in lineList" :key="index" :label="item.lineName" :value="item.lineId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="安装杆塔" :label-width="formLabelWidth" prop="towerIds">
          <el-select v-model.trim="filterWeatherStaionForm.towerIds" multiple clearable filterable placeholder="请选择安装杆塔">
          <el-option v-for="(item, index) in towerList" :key="index" :label="item.towerName" :value="item.towerId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="基站名称" :label-width="formLabelWidth" prop="fuzzyName">
        <el-input v-model.trim="filterWeatherStaionForm.fuzzyName" auto-complete="off" placeholder="请输入模块名称关键字"></el-input>
      </el-form-item>
      <el-form-item label="基站MAC" :label-width="formLabelWidth" prop="fuzzyMac">
        <el-input v-model.trim="filterWeatherStaionForm.fuzzyMac" auto-complete="off" placeholder="请输入模块序列号关键字"></el-input>
      </el-form-item>
    </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button class="cancel-button" @click="handleFilterDevice(false)">取 消</el-button>
      <el-button class="save-button" @click="handleFilterDevice(true)">应 用</el-button>
    </span>
    </el-dialog>

    <!-- 新增/修改气象基站对话框 -->
    <el-dialog class="my-dialog " :title="addWeatherStationDialogVisible ? '新增气象基站' : '更新气象基站'" :close-on-click-modal="false" :visible.sync="addWeatherStationDialogVisible" @closed="handleCloseDialog('weatherStationFormRef')" >
      <el-form :model="weatherStationForm"  ref="weatherStationFormRef" :rules="weatherStationFormRules">
      <el-form-item label="所属线路" :label-width="formLabelWidth" prop="lineId">
          <el-select v-model.trim="weatherStationForm.lineId" clearable filterable placeholder="请选择所属线路" @change="handleLineChange(weatherStationForm.lineId)">
          <el-option v-for="(item, index) in lineList" :key="index" :label="item.lineName" :value="item.lineId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="安装杆塔" :label-width="formLabelWidth" prop="towerId">
          <el-select v-model.trim="weatherStationForm.towerId" clearable filterable placeholder="请选择安装杆塔">
          <el-option v-for="(item, index) in towerList" :key="index" :label="item.towerName" :value="item.towerId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="基站名称" :label-width="formLabelWidth" prop="weatherStationName">
        <el-input v-model.trim="weatherStationForm.weatherStationName" auto-complete="off" placeholder="请输入气象基站名称"></el-input>
      </el-form-item>
      <el-form-item label="基站MAC" :label-width="formLabelWidth" prop="weatherStationMac">
        <el-input v-model.trim="weatherStationForm.weatherStationMac" auto-complete="off" placeholder="请输入气象基站MAC"></el-input>
      </el-form-item>
        <el-form-item label="备注内容" :label-width="formLabelWidth" prop="remark">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model.trim="weatherStationForm.remark" auto-complete="off" placeholder="请输入备注内容"></el-input>
      </el-form-item>
    </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button class="cancel-button" @click="addWeatherStationDialogVisible = false">取 消</el-button>
      <el-button class="save-button" @click="handleSaveWeatherStation('weatherStationFormRef')">保 存</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";
import notEmptyParams from "@/utils/objectUtil.js";
import { getTowerNameList } from "@/http/towerListDetailsApi.js";
import { getLineNames } from "@/http/homeApi.js";
import {getWeatherStations, addWeatherStation, updateWeatherStation, deleteWeatherStation} from "@/http/equipmentApi.js";
import moment from 'moment';
export default {
  name: "weatherStationTable",

   data() {
    return {
      /*
       * @Date 2022/07/06 16:10:38
       * 基站表格信息
       */
      weatherStationTableData: [],


      /*
       * @Date 2022/07/06 16:14:05
       * 分页信息
       */
      pageNumber: 1,
      pageSize: 10,
      total: 0,


      /*
       * @Date 2022/07/06 16:11:10
       * 过滤表格
       */
      filterWeatherStationDialogVisible: false,
      formLabelWidth: "80px",
      filterWeatherStaionForm: {
        lineId: "",
        towerIds: [],
        fuzzyName: "",
        fuzzyMac: ""
      },
      lineList: [],
      towerList: [],


      /*
       * @Date 2022/07/06 16:11:56
       * 新增 / 修改基站信息
       */
      isAddStation: true,
      addWeatherStationDialogVisible: false,
      weatherStationForm: {
        lineId: "",
        towerId: "",
        weatherStationName: "",
        weatherStationMac: "",
        remark: "",
        installDate: moment().format("YYYY-MM-DD HH:mm:ss")
      },
      weatherStationFormRules: {
        lineId: [{ required: true, message: "请选择所属线路", trigger: "blur" }],
        towerId: [{ required: true, message: "请选择安装杆塔", trigger: "blur" }],
        weatherStationName: [{ required: true, message: "请输入气象基站名称", trigger: "blur" }],
        weatherStationMac: [{ required: true, message: "请输入气象基站MAC", trigger: "blur" }]
      }
    };
  },

  components: {
    Icon,
  },

  methods: {
    /*
    * @Date 2022/03/09 15:11:23
    * 查询气象基站
    */
    handleGetWeatherStations() {
      let params = notEmptyParams(this.filterWeatherStaionForm);
      params.pageNumber = this.pageNumber;
      params.pageSize = this.pageSize;
      getWeatherStations(params).then(res => {
        this.weatherStationTableData = res.rows;
        this.total = res.total;
      });
    },

    /*
    * @Date 2022/03/09 16:05:02
    * 处理切换线路
    */
    handleLineChange(lineId) {
      if(lineId) {
        this.weatherStationForm.towerId = "";
        this.filterWeatherStaionForm.towerIds = [];
        getTowerNameList({lineId}).then(res => {
          this.towerList = res.data;
        })
      }
    },

    /*
    * @Date 2022/03/09 15:18:20
    * 过滤设备
    */
    handleFilterDevice(isFilter) {
      if(!isFilter) {
        this.$refs["filterWeatherStaionFormRef"].resetFields();
      }
      this.handleGetWeatherStations();
      this.filterWeatherStationDialogVisible = false;
    },

    /*
    * @Date 2022/03/09 16:00:46
    * 打开新增气象基站弹窗
    */
    handleAddWeatherStation() {
      this.addWeatherStationDialogVisible = true;
      this.isAddStation = true;
    },

    /*
     * @Date 2022/03/09 16:48:29
     * 打开编辑气象基站弹窗
     */
    handleEdit(row) {
      this.addWeatherStationDialogVisible = true;
      this.isAddStation = false;
      this.$nextTick(() => {
        this.weatherStationForm = JSON.parse(JSON.stringify(row))
         getTowerNameList({lineId: row.lineId}).then(res => {
          this.towerList = res.data;
        })
      })
    },

    /*
    * @Date 2022/03/08 11:38:05
    * 处理关闭弹窗
    */
    handleCloseDialog(formRef) {
      this.$refs[formRef].resetFields();
      this.$refs[formRef].clearValidate();
    },

    /*
    * @Date 2022/03/09 16:00:07
    * 处理保存气象基站
    */
    handleSaveWeatherStation(formRef) {

      this.$refs[formRef].validate(valid => {
        if(valid) {
          if(this.isAddStation) {
            addWeatherStation(this.weatherStationForm).then(res => {
              if(res.code="000000") {
                this.$message.success(res.data);
                this.handleGetWeatherStations();
              }else{
                this.$message.warning(res.data);
              }
              this.addWeatherStationDialogVisible = false;
            })
          }else {
            updateWeatherStation(this.weatherStationForm).then(res => {
              if(res.code="000000") {
                this.$message.success(res.data);
                this.handleGetWeatherStations();
              }else{
                this.$message.warning(res.data);
              }
              this.addWeatherStationDialogVisible = false;
            })
          }
        }
      })
    },

    /*
     * @Date 2022/03/09 16:48:48
     * 删除气象基站
     */
    handleDelete(row) {
       this.$confirm("此操作将永久删除气象基站, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(res => {
          deleteWeatherStation({ id: row.id })
            .then(res => {
              if (res.code == '000000') {
                this.$message.success(res.mesg);
                this.handleGetWeatherStations();
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err, "取消删除");
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/08/25 19:37:59
     * @Description 分页切换
     */
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.handleGetWeatherStations();
    }
  },
mounted() {
  /*
   * @Date 2022/03/09 16:03:07
   * 查询气象基站表格数据
   */
  this.handleGetWeatherStations();

  /*
   * @Date 2022/03/09 16:03:21
   * 查询巡检线路
   */
  getLineNames().then(res => {
    this.lineList = res.data;
  })
}
  
}
</script>

<style scoped lang="scss">

</style>