<template>
  <div class="reboot-table device-table">
    <div class="top-button">
      <el-button title="添加重启模块" size="small" class="kr-normal-button top-item" @click="handleAdd">添加重启模块</el-button>
    </div>

    <el-table :data="rebootModelTableData" border style="width: 100%" height="78vh">
       <el-table-column prop="date" label="序号" align="center" width="80">
        <template slot-scope="scope">
          <Icon name="bottomArrow" />
          <span>{{ scope['$index'] + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="robotName" label="所属机器" align="center"></el-table-column>
      <el-table-column prop="deviceName" label="模块名称" align="center"></el-table-column>
      <el-table-column prop="onlineStatus" label="模块状态" align="center"></el-table-column>
      <el-table-column prop="deviceImei" label="模块序列号" align="center"></el-table-column>
      <el-table-column prop="reboot" label="机器人重启开关" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.reboot" active-color="#13ce66" inactive-color="#ccc" active-value="1" inactive-value="0" @change="Switch(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column  label="重启记录" align="center" width="120">
          <template slot-scope="scope">
            <el-tag size="small" @click="handleRebootModelRecords(scope.row)">查看记录</el-tag>
          </template>
        </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button title="编辑设备信息" @click="handleEdit(scope.row)" type="text" class="table-btn"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
          <el-button title="删除设备" @click="deleteReboot(scope.row)" type="text" class="table-btn"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-class">
       <el-pagination 
        @current-change="handlePageChange(pageNumber)" 
        :current-page.sync="pageNumber" 
        :page-size="pageSize" 
        layout="total, prev, pager, next" 
        :total="total"
      >
        </el-pagination>
    </div>

    <!-- 新增 / 修改重启模块 -->
    <el-dialog class="my-dialog" :title="robotDialogTitle" :close-on-click-modal="false" :visible.sync="robotDialogVisible" @closed="closedDialog('deviceForm')">
      <el-form :model="deviceForm" :rules="deviceFormRules" ref="deviceForm">
        <el-form-item label="所属机器" :label-width="formLabelWidth" prop="robotMac">
          <el-select v-model.trim="deviceForm.robotMac" placeholder="请选择机器人">
            <el-option v-for="(item, index) in robotOption" :key="index" :label="item.robotName" :value="item.robotMac"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称" :label-width="formLabelWidth" prop="deviceName">
          <el-input v-model.trim="deviceForm.deviceName" auto-complete="off" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="设备序列号" :label-width="formLabelWidth" prop="deviceImei">
          <el-input v-model.trim="deviceForm.deviceImei" auto-complete="off" placeholder="请输入序列号"></el-input>
        </el-form-item>
        <!-- <el-form-item label="机器Mac" :label-width="formLabelWidth" prop="robotMac">
          <el-input v-model.trim="deviceForm.robotMac" auto-complete="off" placeholder="请输入机器人Mac地址"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="robotDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="save">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看重启记录 -->
    <el-dialog class="my-dialog2 restart-record-dialog" title="重启记录" :close-on-click-modal="false" width="80vw" top="10vh" :visible.sync="rebootRecordDialogVisible" @close="handleCloseDialog('rebootRecordFormRef')"  >
      <el-form :model="rebootRecordForm" inline ref="rebootRecordFormRef" :rules="rebootRecordFormRules">
        <el-form-item label="起始时间" :label-width="formLabelWidth" prop="beginTime">
           <el-date-picker
            v-model="rebootRecordForm.beginTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" :label-width="formLabelWidth" prop="endTime">
           <el-date-picker
            v-model="rebootRecordForm.endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
           <el-form-item label="结果" :label-width="formLabelWidth" prop="result">
           <el-select v-model.trim="rebootRecordForm.result"  clearable filterable collapse-tags placeholder="请选择结果类型">
            <el-option v-for="(item, index) in resultList" :key="index" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
        <el-button class="kr-normal-button" size="small" @click="handleSelectRebootRecord('rebootRecordFormRef')">查询</el-button>
      </el-form-item>
      </el-form>
      <div style="display: flex; flex-direction: row;">
      <el-table :data="rebootRecordTableData" border style="width: 60%" height="70vh" empty-text="没有查询到匹配的重启记录！" >
        <el-table-column label="序号" align="center" width="80">
          <template slot-scope="scope">
          <Icon name="bottomArrow" />
          <span>{{ scope['$index'] + 1 }}</span>
        </template>
        </el-table-column>
      <el-table-column prop="actionValue" label="操作" align="center"></el-table-column>
      <el-table-column prop="resultDesc" label="结果" align="center"></el-table-column>
       <el-table-column prop="creatorName" label="操作用户" align="center"></el-table-column>
        <el-table-column prop="createTime" label="操作时间" align="center"></el-table-column>
      </el-table>
      </div>
      <div class="pager-class">
        <el-pagination 
          @current-change="handleRecordPageChange(pageNumber)" 
          :current-page.sync="pageNumber" 
          :page-size="pageSize" 
          layout="total, prev, pager, next" 
          :total="total" 
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addRebootModel, deleteRebootModel, getRebootModel, updateRebootModel ,getControlRecord} from "@/http/rebootModelApi.js";
import { controlStation } from "@/http/baseStationApi.js";

import { getRobots } from "@/http/equipmentApi.js";
// import qs from "qs";
import Icon from "@/components/Icon.vue";
import { mapState } from "vuex";
import moment from 'moment';
export default {
  name: "rebootModelTable",
  components: {
    Icon
  },
  data() {
    return {
    
      /*
       * @Date 2022/07/06 17:51:02
       * 重启模块表格信息
       */
      rebootModelTableData: [],
      reboot: "" ,



      /*
       * @Date 2022/07/06 17:52:29
       * 分页信息
       */
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      

      /*
       * @Date 2022/07/06 17:55:28
       * 新增 / 重启模块信息
       */
      isAdd: true,
      robotDialogTitle: "添加设备",
      robotDialogVisible: false,
      formLabelWidth: "5vw",
      robotOption: [],
      deviceForm: {
        deviceImei: "",
        deviceName: "",
        robotMac: ""
      },
      rebootRecordTableData: [],
      deviceFormRules: {
        deviceName: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        deviceImei: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        robotMac: [
          { required: true, message: "请输入机器人Mac地址", trigger: "blur" },
          {
            required: true,
            min: 12,
            max: 12,
            message: "机器人Mac地址为12位",
            trigger: "blur"
          }
        ]
      },


      /*
       * @Date 2022/07/06 17:57:23
       * 重启记录信息
       */
      rebootRecordDialogVisible: false,
      rebootRecordTableData: [],
      resultList: [               // 表单可选的结果类型
        {key: 1, value: "成功"},
        {key: 0, value: "失败"}
      ],
      rebootRecordForm: {
        rebootModuleMac: "",
        result: "",
        beginTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
      },
      rebootRecordFormRules: {
        // beginTime: [{required: true, message: "请选择起始时间", trigger: "blur"}],
        // endTime: [{required: true, message: "请选择结束时间", trigger: "blur"}]
      },
    };
  },
  computed: {
    ...mapState(["userMessage"])
  },
  methods: {
    /**
     * @Author KR0288
     * @Date 2022/07/07 08:38:24
     * @Description 获取表格数据
     */
    getRebootModelTableData() {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      getRebootModel(params).then(res => {
        console.log(res);
        this.rebootModelTableData = res.rows;
        this.total = res.total;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 09:10:14
     * @Description 切换机器人重启开关
     */
    Switch(row) {
      let params = {
        stationId: row.id,
        control: row.reboot,
        name: "rebootModel",
        num: 0,
        robotMac: row.robotMac,
        userName: this.userMessage.userName
      };
      controlStation(params)
        .then(res => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.warning(res.mesg);
            row.reboot = "0";
          }
        })
        .catch(err => {
          if (err.status == 500) {
            this.$message.warning(err.data.data);
          }
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 08:44:18
     * @Description 新增按钮
     */
    handleAdd() {
      this.isAdd = true;
      this.robotDialogTitle = "新增设备信息";
      this.showDialog();
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 08:44:25
     * @Description 编辑按钮
     */
    handleEdit(row) {
      this.deviceForm = JSON.parse(JSON.stringify(row));
      this.isAdd = false;
      this.robotDialogTitle = "修改设备信息";
      this.showDialog();
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 08:46:01
     * @Description 打开对话框
     */
    showDialog() {
      this.robotDialogVisible = true;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 08:46:14
     * @Description 对话框保存按钮
     */
    save() {
       if (this.deviceForm.robotMac) {
           let robot = this.robotOption.find(r => {
            return r.robotMac.indexOf(this.deviceForm.robotMac)>-1;
         });
         this.deviceForm.bureauId = robot.bureauId;
       }
      if (this.isAdd) {
        this.addReboot();
      } else {
        this.updateReboot();
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 08:46:23
     * @Description 添加设备
     */
    addReboot() {
      let params = this.deviceForm;
     
      this.$refs.deviceForm.validate(valid => {
       
        if(valid){
          addRebootModel(params)
            .then(res => {
              if (res.code == "000000") {
                this.$message.success(res.data ? res.data : res.mesg);
                this.robotDialogVisible = false;
                this.getRebootModelTableData();
              } else {
                this.$message.warning(res.data ? res.data : res.mesg);
              }
            })
            .catch(err => {
              if (err.status == 500) {
                this.$message.warning(err.data.data);
              }
            });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 08:47:01
     * @Description 更新设备
     */
    updateReboot() {
      let params = this.deviceForm;
      this.$refs.deviceForm.validate(valid => {
        if (valid) {
          updateRebootModel(params).then(res => {
            if (res.code == "000000") {
              this.$message.success(res.data ? res.data : res.mesg);
              this.robotDialogVisible = false;
              this.getRebootModelTableData();
            } else {
              this.$message.error(res.data ? res.data : res.mesg);
            }
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 09:09:48
     * @Description 关闭对话框时回调
     */
    closedDialog(formName) {
      this.$refs[formName].clearValidate();
      this.isAdd = true;
      this.robotDialogTitle = "添加设备";
      this.deviceForm = {
        deviceImei: "",
        deviceName: "",
        robotMac: ""
      };
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 08:59:29
     * @Description 删除设备
     */
    deleteReboot(row) {
      this.$confirm("此操作将永久删除设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(res => {
          console.log(res);
          deleteRebootModel({ id: row.id })
            .then(res => {
              if (res.code == "000000") {
                this.getRebootModelTableData();
                this.$message.success(res.data ? res.data : res.mesg);
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err, "取消操作");
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 08:59:40
     * @Description 打开重启记录框，查询重启记录
     */
    handleRebootModelRecords(row){
      this.rebootRecordDialogVisible = true;
      this.reboot = row;
      setTimeout(() => {
        this.pageNumber = 1;
        this.getAllRecord(row);
      }, 500);
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 09:02:03
     * @Description 获取重启记录的表格数据
     */
    getAllRecord(row){
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        stationId: row.rebootModelId
      };
     getControlRecord(params).then(res=>{
        if(res.total==0){
            this.$message.warning("当前条件没有查询到匹配的重启记录！");
        }
         this.rebootRecordTableData = res.rows;
         this.total=res.total;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 09:02:21
     * @Description 根据表单信息查询重启记录
     */
    handleSelectRebootRecord(ref){
      let begin = moment(this.rebootRecordForm.beginTime, "YYYY-MM-DD HH:mm:ss");
      let end = moment(this.rebootRecordForm.endTime, "YYYY-MM-DD HH:mm:ss");
      if(begin&&end){
          if(end.isBefore(begin)) {
          this.$message.warning("查询失败，选择的结束时间比开始时间要晚！");
          return;
        }
      }
    
      if(begin&&!end){
        this.message.warning("请选择结束时间")
        return;
      }

      this.$refs[ref].validate(valid => {
        if(valid) {
          let params = {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            stationId: this.reboot.rebootModelId,
            type: this.rebootRecordForm.result,
            beginTime: this.rebootRecordForm.beginTime,
            endTime:this.rebootRecordForm.endTime
          };
          getControlRecord(params).then(res => {
            if(res.total==0) {
              this.$message.warning("当前条件没有查询到匹配的重启记录！");
            }
            this.rebootRecordTableData = res.rows;
          })
        }
      })
    },
  
    /**
     * @Author KR0288
     * @Date 2022/07/07 09:04:44
     * @Description 重启记录页码改变
     */
    handleRecordPageChange(pageNumber){
     this.pageNumber = pageNumber;
     if(!beginTime&&!endTime){

     }
     this.getAllRecord(this.reboot);
   },
   
    /**
     * @Author KR0288
     * @Date 2022/07/07 09:05:52
     * @Description 关闭重启记录对话框
     */
    handleCloseDialog(ref){
      this.rebootRecordDialogVisible = false;
      this.pageNumber = 1;
      this.getRebootModelTableData();
      // this.rebootRecordTableData = [];
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 09:10:49
     * @Description 点击分页页码
     */
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getRebootModelTableData();
    }
  },
  mounted() {
    this.getRebootModelTableData();
    getRobots({ 
      pageNumber: 1, 
      pageSize: 500,
      robotType: 'ROBOT_TYPE_50'
    }).then(res => {
      this.robotOption = res.rows;
    });
  }
};
</script>
<style lang="scss" scoped>
.pager-class {
  display: flex;
  justify-content: center;
}
</style>
