// 过滤对象，将对象非空对象取出
export function toType (obj) {
  return {}.toString
    .call(obj)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase();
}

// 移除空对象
export function filterNull (o) {
  for (var key in o) {
    if (o[key] === null || o[key] === "" || o[key] === undefined) {
      delete o[key];
    }
    if (toType(o[key]) === "string") {
      o[key] = o[key].trim();
    } else if (toType(o[key]) === "object") {
      o[key] = filterNull(o[key]);
    } else if (toType(o[key]) === "array") {
      if (o[key].length === 0) {
        delete o[key];
      }
    }
  }
  return o;
}


// 创建中间对象
export default function notEmptyParams (obj) {
  // 借助 JSON 实现深拷贝
  let tempStr = JSON.stringify(obj);
  let temp = JSON.parse(tempStr);
  return filterNull(temp);
}
