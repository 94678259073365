<template>
  <div class="equipment-page">
    <RouterMenu />
    <el-row class="tac" :gutter="20">
      <el-col :span="3" style="height: 90vh">
        <h3 class="menu-title">设备菜单</h3>
        <el-scrollbar :native="true">
          <div class="equipment-menu">
            <el-menu 
              :default-active="currentMenuIndex" 
              class="el-menu-vertical-demo" 
              @select="handleSelect" 
              text-color="#fff" 
              active-text-color="#00ffbf"
            >
              <el-menu-item index="robot" v-if="$hasElement('robot-tab-manage')">
                <i class="iconfont menu-icon icon-jiqiren"></i>
                <span slot="title">机器人</span>
              </el-menu-item>
              <el-menu-item index="camera" v-if="$hasElement('camera-tab-manage')">
                <i class="iconfont  menu-icon icon-shexiangtou_shiti"></i>
                <span slot="title">摄像头</span>
              </el-menu-item>
               <el-menu-item index="charge" v-if="$hasElement('charge-tab-manage')">
                <i class="iconfont  menu-icon icon-taiyangneng"></i>
                <span slot="title">充电基站(组网)</span>
              </el-menu-item>
               <el-menu-item index="weather" v-if="$hasElement('weatherStation-tab-manage')">
                <i class="iconfont  menu-icon icon-qixiangjiance"></i>
                <span slot="title">气象基站(组网)</span>
              </el-menu-item>
              <el-menu-item index="reboot-25kg" v-if="$hasElement('restart-tab-manage')">
                <i class="iconfont  menu-icon icon-ziyuanxhdpi"></i>
                <span slot="title">重启模块（轻量化）</span>
              </el-menu-item>
              <el-menu-item index="reboot-compose" v-if="$hasElement('reboot-tab-manage') ">
                <i class="iconfont  menu-icon icon-shebeiguanli"></i>
                <span slot="title">重启模块（组网）</span>
              </el-menu-item>
              <el-menu-item index="warn-notification" v-if="$hasElement('alarm-tab-manage')">
                <i class="iconfont  menu-icon icon-yujing"></i>
                <span slot="title">告警通知</span>
              </el-menu-item>
            </el-menu>
            <!-- <div style="width: 1em; height: 1em; position: absolute; top: 0; left: 0; border-left: solid 2px #00ffbf;  border-top: solid 2px #00ffbf" />
            <div style="width: 1em; height: 1em; position: absolute; top: 0; right: 0; border-right: solid 2px #00ffbf;  border-top: solid 2px #00ffbf" />
            <div style="width: 1em; height: 1em; position: absolute; bottom: 0; left: 0; border-left: solid 2px #00ffbf;  border-bottom: solid 2px #00ffbf" />
            <div style="width: 1em; height: 1em; position: absolute; bottom: 0; right: 0; border-right: solid 2px #00ffbf;  border-bottom: solid 2px #00ffbf" /> -->
          </div>
        </el-scrollbar>
      </el-col>
      <el-col :span="21">
        <div class="table-class">
          <!-- 机器人表格 -->
          <robot-table-menu v-if="'robot' == currentMenuIndex" />
          <!-- 摄像头表格 -->
          <camera-table-menu v-else-if="'camera' == currentMenuIndex" />
          <!-- 充电基站 -->
          <base-station-table v-else-if="'charge' == currentMenuIndex" />
          <!-- 重启设备 -->
          <reboot-model-table v-else-if="'reboot-compose' == currentMenuIndex" />
          <!-- 25KG 远程重启 -->
          <reboot-slight-robot-table v-else-if="'reboot-25kg'=== currentMenuIndex" />
          <!-- 气象基站 -->
          <weather-station-table v-else-if="'weather' == currentMenuIndex" />
          <!-- 重启设备 -->
          <!-- <reboot-model-table-new v-if="'reboot-hisotry' == currentMenuIndex" /> -->
          <!-- 重启设备 -->
          <!-- <reboot-model-table v-if="'charge' == currentMenuIndex" /> -->

          <!-- 告警通知 -->
          <warn-notice-table v-else-if="'warn-notification' == currentMenuIndex" />

        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import RouterMenu from "@/components/RouterMenu.vue";
import robotTableMenu from "@/components/equipmentMenu/robotTableMenu.vue";
import CameraTableMenu from "../../components/equipmentMenu/cameraTableMenu.vue";
import baseStationTable from "../../components/equipmentMenu/baseStationTable.vue";
import rebootModelTable from "../../components/equipmentMenu/rebootModelTable.vue";
import rebootSlightRobotTable from "../../components/equipmentMenu/rebootSlightRobotTable.vue";
import weatherStationTable from '../../components/equipmentMenu/weatherStationTable.vue';
import warnNoticeTable from '../../components/equipmentMenu/warnNoticeTable/warnNoticeTable.vue'
export default {
  name: "equipmentMenu",
  components: {
    RouterMenu,
    robotTableMenu,
    CameraTableMenu,
    baseStationTable,
    rebootModelTable,
    rebootSlightRobotTable,
    weatherStationTable,
    warnNoticeTable
  },
  data() {
    return {
      currentMenuIndex: "robot",
    };
  },

  methods: {
    /**
     * @Author KR0132
     * @Date 2022/02/17 10:01:44
     * @Description 处理单击菜单
     */
    handleSelect(index) {
      this.currentMenuIndex = index;
      console.log('currentIndex: ' + index);
    }
  }
};
</script>
<style lang="scss" scoped>
.equipment-page {
  height: calc(100% - 5vh);
  padding: 0 2vw;
  .table-class {
    margin-top: 1vh;
    .el-table {
      .table-btn {
        padding: 0;
        .icon {
          width: 1vw;
          height: 1vw;
        }
      }
    }
  }
}
</style>
