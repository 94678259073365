<template>
  <div class="device-table">


    <div class="top-button">
      <el-input v-model="searchKey" placeholder="搜索机器名称、MAC地址" clearable class="normal-input top-item top-input search-input" @input="query"></el-input>
      <el-button title="添加摄像头" size="small" class="kr-normal-button top-item" @click="handleAddRobot">新增机器人</el-button>
    </div>


    <el-table :data="robotTableData" border style="width: 100%" height="78vh">
       <el-table-column prop="date" label="序号" align="center" width="80">
        <template slot-scope="scope">
          <Icon name="bottomArrow" />
          <span>{{ scope['$index'] + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="robotName" label="机器人名称" align="center"></el-table-column>
      <el-table-column prop="robotType" label="类型" align="center">
        <template slot-scope="scope">
          <span>{{ robotType(scope.row.robotType) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="robotIp" label="机器人IP" align="center"></el-table-column>
      <el-table-column prop="robotPort" label="端口" align="center"></el-table-column>
      <el-table-column prop="lineName" label="所属线路" align="center"></el-table-column>
      <el-table-column prop="fgtpIp" label="4G图传IP" align="center"></el-table-column>
      <el-table-column prop="robotMac" label="MAC地址" align="center"></el-table-column>
      <el-table-column prop="serialNumber" label="序列号" align="center"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button title="编辑机器人信息" @click="handleEdit(scope.row)" type="text" class="table-btn"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
          <el-button title="删除机器人" @click="deleteRobot(scope.row)" type="text" class="table-btn"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-class">
      <el-pagination @current-change="handlePageChange(pageNumber)" :current-page.sync="pageNumber" :page-size="pageSize" layout="total, prev, pager, next" :total="total"></el-pagination>
    </div>


    <el-dialog class="my-dialog" :title="robotDialogTitle" :close-on-click-modal="false" :visible.sync="robotDialogVisible" @closed="closedDialog('robotForm')">
      <el-form :model="robotForm" :rules="robotFormRules" ref="robotForm">
        <el-form-item label="机器人名称" :label-width="formLabelWidth" prop="robotName">
          <el-input v-model.trim="robotForm.robotName" auto-complete="off" placeholder="请输入机器人名称"></el-input>
        </el-form-item>
        <el-form-item label="机器类型" :label-width="formLabelWidth" prop="robotType">
          <el-select v-model.trim="robotForm.robotType" placeholder="请选择机器人类型">
            <el-option v-for="(item, index) in robotTypeOption" :key="index" :label="item.label" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属公司" :label-width="formLabelWidth" prop="robotType">
          <el-select v-model.trim="robotForm.bureauId" placeholder="请选择公司">
            <el-option v-for="(item, index) in compOption" :key="index" :label="item.label" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属线路" :label-width="formLabelWidth" prop="lineId">
          <el-select v-model.trim="robotForm.lineId" placeholder="请选择机器人所属线路">
            <el-option v-for="(line, index) in allLinesOption" :key="index" :label="line.lineName" :value="line.lineId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机器人IP" :label-width="formLabelWidth" prop="robotIp">
          <el-input v-model.trim="robotForm.robotIp" auto-complete="off" placeholder="请输入机器人IP地址"></el-input>
        </el-form-item>
        <el-form-item label="端口号" :label-width="formLabelWidth" prop="robotPort">
          <el-input v-model.trim="robotForm.robotPort" auto-complete="off" placeholder="请输入端口号"></el-input>
        </el-form-item>
        <el-form-item label="4G图传IP" :label-width="formLabelWidth" prop="fgtpIp">
          <el-input v-model.trim="robotForm.fgtpIp" auto-complete="off" placeholder="请输入4G图传IP"></el-input>
        </el-form-item>
        <el-form-item label="MAC地址" :label-width="formLabelWidth" prop="robotMac">
          <el-input v-model.trim="robotForm.robotMac" auto-complete="off" placeholder="请输入机器人MAC地址"></el-input>
        </el-form-item>
        <el-form-item label="序列号" :label-width="formLabelWidth" prop="serialNumber">
          <el-input v-model.trim="robotForm.serialNumber" auto-complete="off" placeholder="请输入序列号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="robotDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { 
  getRobots, 
  getAllLineList, 
  addRobot, 
  getRobotType, 
  delRobot, 
  updateRobot, 
  getRobotFuzzy, 
  getBureauList 
} from "@/http/equipmentApi.js";
import Icon from "@/components/Icon.vue";

export default {
  name: "robotTableMenu",

  components: {
    Icon
  },

  data() {
    return {
      /*
       * @Date 2022/07/06 13:20:12
       * 搜索框信息
       */
      searchKey: "",


      /*
       * @Date 2022/07/06 13:20:26
       * 表格信息
       */
      robotTableData: [],


      /*
       * @Date 2022/07/06 13:23:10
       * 分页信息
       */
      pageNumber: 1,
      pageSize: 10,
      total: 0,


      /*
       * @Date 2022/07/06 13:24:14
       * 新增 / 修改机器人信息
       */
      isAdd: true,
      robotDialogTitle: "添加机器人",
      robotDialogVisible: false,
      formLabelWidth: "5vw",
      robotTypeOption: [],          // 机器人类型可选列表
      allLinesOption: [],           // 机器人所属线路列表
      compOption: [],               // 机器人所属公司列表
      robotForm: {
        fgtpIp: "192.168.1.164",
        lineId: "",
        robotName: "",
        robotPort: 3000,
        robotIp: "192.168.1.22",
        robotMac: "",
        robotType: "",
        serialNumber: "",
        bureauId: ""
      },
      robotFormRules: {
        robotName: [{ required: true, message: "请输入机器人名称", trigger: "blur" }],
        robotType: [{ required: true, message: "请选择机器人类型", trigger: "blur" }],
        bureauId: [{ required: true, message: "请选择公司", trigger: "blur" }],
        //  lineId: [{ required: true, message: "请选择所属线路", trigger: "blur" }],
        robotIp: [
          { required: true, message: "请输入机器人IP地址", trigger: "blur" },
          {
            required: true,
            message: "IP地址格式错误",
            pattern: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
            trigger: "blur"
          }
        ],
        robotPort: [
          { required: true, message: "请输入机器人端口号", trigger: "blur" },
          {
            required: true,
            pattern: /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]{1}|6553[0-5])$/,
            message: "端口格式错误",
            trigger: "blur"
          }
        ],
        fgtpIp: [
          { required: true, message: "请输入4G图传IP", trigger: "blur" },
          {
            required: true,
            message: "IP地址格式错误",
            pattern: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
            trigger: "blur"
          }
        ],
        robotMac: [
          { required: true, message: "请输入机器人Mac地址", trigger: "blur" },
          {
            required: true,
            min: 11,
            max: 12,
            message: "机器人Mac地址为12位",
            trigger: "blur"
          }
        ],
        rtmp: [{ required: true, message: "请输入摄像头RTMP地址", trigger: "blur" }],
        serialNumber: [{ required: true, message: "请输入机器人序列号", trigger: "blur" }]
      }
    };
  },

  computed: {
    robotType() {
      return (type) => {
        let result = this.robotTypeOption.find(item => item.type === type);
        return result ? result.label : "未知"
      }
    }
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/07/05 17:11:54
     * @Description 模糊查询
     */
    query() {
      this.getRobotTableData();
    },

    /**
     * @Author KR0288
     * @Date 2022/07/05 17:12:48
     * @Description 获取机器人表格数据
     */
    getRobotTableData() {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        keyword: this.searchKey
      };
      getRobots(params).then(res => {
        this.robotTableData = res.rows;
        this.total = res.total;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:31:59
     * @Description 点击新增机器人按钮
     */
    handleAddRobot() {
      this.showDialog();
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:29:47
     * @Description 打开对话框
     */
    showDialog() {
      this.robotDialogVisible = true;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:34:01
     * @Description 获取全部线路,添加时下拉框选择
     */
    getAllLineList() {
      getAllLineList().then(res => {
        this.allLinesOption = res.data;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:34:09
     * @Description 获取机器人类型，添加时下拉框选择
     */
    getRobotTypeList() {
      getRobotType().then(res => {
        if (res.code === "000000") {
          this.robotTypeOption = res.data.map(item => ({
            // type: String(item.number),
            type: item.key,
            label: item.value
          }));
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:34:17
     * @Description 获取公司列表
     */
    getBureauList() {
      getBureauList().then(res => {
        if (res.code === "000000") {
          this.compOption = res.data.map(item => ({
            type: item.bureauId,
            label: item.bureauName,
            ...item
          }));
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:30:21
     * @Description 编辑按钮
     */
    handleEdit(row) {
      this.robotForm = JSON.parse(JSON.stringify(row));
      this.isAdd = false;
      this.robotDialogTitle = "修改机器人信息";
      this.showDialog();
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:31:19
     * @Description 保存机器人
     */
    save() {
      if (this.isAdd) {
        this.addRobotBtn();
      } else {
        this.updateRobot();
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:32:19
     * @Description 保存添加机器人
     */
    addRobotBtn() {
      let params = this.robotForm;
      this.$refs.robotForm.validate(valid => {
        if (valid) {
          addRobot(params)
            .then(res => {
              if (res.code == "000000") {
                this.$message.success(res.mesg);
                this.robotDialogVisible = false;
                this.getRobotTableData();
              } else {
                this.$message.error(res.data);
              }
            })
            .catch(err => {
              if (err.status == 500) {
                this.$message.error(err.data.data);
              }
            });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:32:33
     * @Description 保存更新机器人
     */
    updateRobot() {
      let params = this.robotForm;
      this.$refs.robotForm.validate(valid => {
        if (valid) {
          updateRobot(params).then(res => {
            if (res.code == "000000") {
              this.$message.success(res.mesg);
              this.robotDialogVisible = false;
              this.getRobotTableData();
            } else {
              +this.$message.error(res.data);
            }
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:29:58
     * @Description 关闭对话框时回调
     */
    closedDialog(formName) {
      this.$refs[formName].clearValidate();
      this.isAdd = true;
      this.robotDialogTitle = "添加机器人";
      this.robotForm = {
        fgtpIp: "192.168.1.164",
        lineId: "",
        robotName: "",
        robotPort: 3000,
        robotIp: "192.168.1.22",
        robotMac: "",
        robotType: "",
        serialNumber: "",
        bureauId: ""
      };
    },

    /**
     * @Author KR0288
     * @Date 2022/07/06 13:32:50
     * @Description 删除机器人
     */
    deleteRobot(row) {
      this.$confirm("此操作将永久删除机器人, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(res => {
          console.log(res);
          delRobot({ id: row.id })
            .then(res => {
              if (res.code == "000000") {
                this.$message.success(res.mesg);
                this.getRobotTableData();
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err, "取消操作");
        });
    },
    
    /**
     * @Author KR0288
     * @Date 2022/07/06 13:35:38
     * @Description 点击分页页码
     */
    handlePageChange(pageNumber) {
        this.pageNumber = pageNumber;
        this.getRobotTableData();
    },
  },

  mounted() {
    this.getRobotTableData();
    this.getAllLineList();
    this.getRobotTypeList();
    this.getBureauList();
  }
  
};
</script>
<style lang="scss" scoped>
.pager-class {
  display: flex;
  justify-content: center;
}
</style>
