<!--
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-05-20 14:09:47
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-07-07 09:32:04
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\components\equipmentMenu\warnNoticeTable.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="warnContainer">
        <!-- 按钮组 -->
        <div class="topBtn">
            <div class="topBtn-left">
                <el-select v-model="currentLineId" placeholder="请选择线路" class="normal-select">
                    <el-option
                        v-for="item in lineGroup"
                        :key="item.lineId"
                        :label="item.lineName"
                        :value="item.lineId" 
                        :popper-append-to-body="false"
                        @change="handleSelectChange"
                    >
                    </el-option>
                </el-select>    
                <el-button class="kr-normal-button" type="primary" @click="searchTableData">查询</el-button>   
            </div>
            <div class="topBtn-right">
                <el-button class="kr-normal-button" type="primary" @click="openAddDialog">新增联系人</el-button>
            </div>
        </div>

        <!-- 表格 -->
        <div class="tableContainer">
            <el-table
                :data="tableData"
                height="78vh"
                style="width: 100%"
                :popper-append-to-body="false">
                <el-table-column 
                    align="center" 
                    label="序号">
                    <template slot-scope="scope">
                        <Icon name="bottomArrow" />
                        <span>{{ scope['$index'] + 1 }}</span>
                    </template>
                </el-table-column>

                <el-table-column 
                    align="center" 
                    v-for="item in tableProps"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.prop">         
                </el-table-column>

                <!-- 重置密码 -->
                <el-table-column 
                    align="center" 
                    label="重置密码">
                    <template slot-scope="scope">
                        <el-button @click="handleReset(scope.row)" type="text" size="small" class="green-normal-small-button">重置</el-button>
                    </template>
                </el-table-column>

                <!-- 操作 -->
                <el-table-column 
                    align="center" 
                    label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)" type="text" size="small" class="green-normal-small-button">更新</el-button>
                        <el-button type="text" size="small" @click="handleDelete(scope.row)" class="green-normal-small-button">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 分页 -->
        <div class="pager-class">
            <el-pagination @current-change="searchTableData" :current-page.sync="pageNumber" :page-size="pageSize" layout="total, prev, pager, next" :total="total"></el-pagination>            
        </div>

        <!-- 重置密码对话框 -->
        <el-dialog
            :visible.sync="resetPasswordVisible"
            @close="handleCloseResetDialog"
            class="my-dialog contactsAddDialog"
            :popper-append-to-body="false">
            <reset-pwd-form 
                :row="currentRow"
                @close="resetPasswordVisible = false"
                ref="contactsResetDialog">
            </reset-pwd-form>
        </el-dialog>

        <!-- 新增、修改联系人对话框 -->
        <el-dialog
            :title="(isAdd ? '新增' : '更新') + '联系人'"
            :visible.sync="contactsDialogVisible"
            @close="handleCloseContacts"
            class="my-dialog contactsAddDialog"
            :popper-append-to-body="false">
                <contacts-form 
                    :lineGroup="lineGroup" 
                    @close="contactsDialogVisible = false" 
                    :isAdd="isAdd"  
                    ref="contactsAddDialog"
                    :row="currentRow">
                </contacts-form>
        </el-dialog>
    </div>
</template>

<script>
import {
    getAllLineList,
    getWarnNoticeTableData,
    deleteContacts,
} from '../../../http/equipmentApi'
import Icon from "@/components/Icon.vue";
import contactsForm from './contactsAddDialog.vue'
import resetPwdForm from './resetPasswordDialog.vue'
export default {
    components: {
        Icon,
        contactsForm,
        resetPwdForm
    },

    data() {
        return {
            // 下拉菜单线路信息
            lineGroup: [],           // 下拉菜单可选项
            currentLineId: '',       // 当前选择的线路


            // 表格信息
            tableData: [],          // 表格数据
            tableProps: [           // 表格属性
            {
                label: '用户名',
                prop: 'username'
            },{
                label: '手机号码',
                prop: 'phoneNumber'
            },{
                label: '绑定线路',
                prop: 'lineName'
            },{
                label: '备注信息',
                prop: 'remark'
            }],

            
            // 新增和更新联系人信息
            isAdd: false,                    // 是否新增
            contactsDialogVisible: false,   // 是否打开对话框
            currentRow: {},                 // 当前选择的表格行
            

            // 重置密码信息
            resetPasswordVisible: false,    // 是否打开对话框


            // 分页信息
            pageNumber: 1,                  // 页数         
            pageSize: 10,                   // 每页展示多少条数据
            total: 0,
        }
    },

    methods: {
        /**
         * @Author KR0288
         * @Date 2022/05/20 14:53:22
         * @Description 获取所有线路信息
         */
        getAllLineInfo() {
            getAllLineList().then(res => {
                if(res.code == '000000') {
                    this.lineGroup = [{
                        bureauId: -1,
                        id: -1,
                        lineId: '',
                        lineName: "请选择线路",
                        towerTotal: 0
                    }, ...res.data];
                    this.currentLineId =  this.lineGroup[0].lineId;
                    this.$message({
                        type: 'success',
                        message: res.mesg
                    })
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data
                    })
                }
            }).catch(err => {
                this.$message({
                    type: 'error',
                    message: err
                })
            })
        },

        /**
         * @Author KR0288
         * @Date 2022/05/20 15:27:31
         * @Description 下拉菜单选中其他值
         */
        handleSelectChange(value) {
            this.currentLineId = value;
        },

        /**
         * @Author KR0288
         * @Date 2022/05/20 14:54:36
         * @Description 根据所选线路查询表格信息
         */
        searchTableData() {
            getWarnNoticeTableData({
                lineId: this.currentLineId,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
            }).then(res => {
                if(res.result == '查询成功') {
                    this.tableData = res.rows
                    this.total = res.total;
                    this.$message({
                        type: 'success',
                        message: res.result
                    })
                }else {
                    this.$message({
                        type: 'error',
                        message: '查询失败'
                    })
                }
            }).catch(err => {
                this.$message({
                    type: 'error',
                    message: err
                })
            })
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 09:54:43
         * @Description 打开新增联系人对话框
         */
        openAddDialog() {
            this.isAdd = true;
            this.contactsDialogVisible = true;
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 09:47:56
         * @Description 关闭新增或修改联系人对话框
         */
        handleCloseContacts() {
            this.$refs.contactsAddDialog.$refs.contactsForm.resetFields();
            this.isAdd = false;
            this.searchTableData();
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 14:47:13
         * @Description 打开重置密码对话框
         */
        handleReset(row) {
            this.resetPasswordVisible = true;
            this.currentRow = row;
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 15:58:56
         * @Description 关闭重置密码对话框
         */
        handleCloseResetDialog() {
            this.$refs.contactsResetDialog.$refs.contactsForm.resetFields();
            this.searchTableData();
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 11:45:16
         * @Description 打开修改联系人对话框
         */
        handleUpdate(row) {
            this.contactsDialogVisible = true;
            this.currentRow = row;
            this.isAdd = false
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 14:39:45
         * @Description 删除联系人
         */
        handleDelete(row) {
            this.$confirm('此操作将永久删除该联系人, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteContacts({
                    id: row.id
                }).then(res => {
                    if(res.code == '000000') {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.searchTableData();
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data
                        })
                    }
                }).catch(err => {
                    this.$message({
                        type: 'error',
                        message: err
                    })
                }) 
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });  
        }
    },

    created() {
        this.getAllLineInfo();
        this.searchTableData();
    }

}
</script>

<style lang="scss" scoped>
    .warnContainer {
        .topBtn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /deep/ .topBtn-left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .el-select {
                    margin-right: 10px;
                }
            }
        }

        /deep/ .tableContainer {
            .el-table__header {
                width: 100% !important;
            }

            .el-table__row:nth-child(odd) {
                background-color: rgba(0, 0, 0, 0.3);
            }

            .el-table__row:nth-child(2n) {
                background-color: rgba(0, 255, 191, 0.05);
            }
        }

        .pager-class {
            display: flex;
            justify-content: center;
        }

        /deep/ .contactsAddDialog {
            .el-dialog {
                width: 40vw !important;
            }
        }
    }
</style>