// 塔杆列表页面接口
import axios from "./ajax.js"; // 导入ajax

/**
 * @Author KR0288
 * @Date 2022/05/18 14:22:12
 * @Description 查询杆塔
 */
export const getqueryTowersList = data => {
  return axios({
    url: `/eprdms/tower/get`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:22:53
 * @Description 查询线路对应杆塔列表
 */
export const getTowerNameList = params => {
  return axios({
    url: `/eprdms/tower/get/simple`,
    method: "get",
    params
  });
};

/*
 * @Date 2022/02/10 18:23:03
 * 查询线路对应杆塔列表
 */
export const getMultiLineTowerNameList = data => {
  return axios({
    url: `/eprdms/tower/get/multi/simple`,
    method: "post",
    data
  });
};

// eprdms/line/downloadTemplate
export const getLinedownloadTemplate = params => {
  return axios({
    url: `/eprdms/line/downloadTemplate`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:24:10
 * @Description 下载线路文档
 */
export const getLinedownloadLine = data => {
  return axios({
    url: `/eprdms/line/downloadLine`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:24:40
 * @Description 查询杆塔类型
 */
export const gettowerType = params => {
  return axios({
    url: `/eprdms/dict/towerType`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:25:19
 * @Description 增加塔杆
 */
export const postaddNewTower = data => {
  return axios({
    url: `/eprdms/tower/add`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:25:33
 * @Description 删除塔杆
 */
export const postdeleteTower = params => {
  return axios({
    url: `/eprdms/tower/delete`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:25:52
 * @Description 修改塔杆
 */
export const postupdateTower = data => {
  return axios({
    url: `/eprdms/tower/update`,
    method: "post",
    data
  });
};
