<!--
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-05-26 09:25:06
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-05-27 15:22:46
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\components\equipmentMenu\contactsDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="contactsContainer">
    <el-form :model="contactsForm" :rules="formRules" ref="contactsForm" label-width="80px" class="contactsForm" :popper-append-to-body="false">
        <el-form-item label="绑定线路" prop="lineId">
            <el-select v-model="contactsForm.lineId" placeholder="请选择线路">
                <el-option :label="lineItem.lineName" :value="lineItem.lineId" v-for="lineItem in lineGroup.slice(1)" :key="lineItem.lineId"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
            <el-input v-model="contactsForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="isAdd">
            <el-input v-model="contactsForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phoneNumber">
            <el-input v-model="contactsForm.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
            <el-input v-model="contactsForm.remark"></el-input>
        </el-form-item>
        <el-form-item class="btnGroup">
            <el-button class="kr-normal-button" @click="cancel">取消</el-button>
            <el-button class="kr-normal-button" @click="submit">保存</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
    addContacts,
    updateContacts,
} from '../../../http/equipmentApi'
export default {
    props: {
        /*
         * @Date 2022/05/26 09:50:21
         * 线路集合
         */
        lineGroup: {
            type: Array,
            default: () => {
                return [];
            }
        },

        /*
         * @Date 2022/05/26 11:29:57
         * 是否新增
         */
        isAdd: {
            type: Boolean,
            default: true
        },

        /*
         * @Date 2022/05/26 11:36:23
         * 当前选择的表格行
         */
        row: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            // 表单信息
            contactsForm: {         // 表单值
                lineId: '',
                username: '',
                password: '',
                phoneNumber: '',
                remark: ''
            },       
            id: '',                 // 用户id
            formRules: {            // 表单校验规则
                lineId: [{
                    required: true, 
                    message: "线路名不能为空", 
                    trigger: "blur" 
                }],
                username: [{
                    required: true, 
                    message: "用户名不能为空", 
                    trigger: "blur" 
                }],              
                password: [{
                    required: true, 
                    message: "密码不能为空", 
                    trigger: "blur" 
                }],
                phoneNumber: [{     
                    required: true, 
                    message: "手机号不能为空", 
                    trigger: "blur" 
                }],
            }
        }
    },

    methods: {
        /**
         * @Author KR0288
         * @Date 2022/05/26 13:07:55
         * @Description 初始化表单
         */
        initForm() {
            if(!this.isAdd && this.row) {
                this.contactsForm.lineId = this.row.lineId;
                this.id = this.row.id
                let keys = Object.keys(this.row).filter(key => {
                    return key != 'id'
                });
                keys.forEach(key => {
                    this.contactsForm[key] = this.row[key];
                })
            }
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 10:22:37
         * @Description 取消新增或修改
         */
        cancel() {
            this.$emit('close');
        },

        /**
         * @Author KR0288
         * @Date 2022/05/26 10:28:56
         * @Description 提交新增联系人或更新联系人
         */
        submit() {
            if(this.isAdd) {
                addContacts(this.contactsForm).then(res => {
                    if(res.code == '000000') {
                        this.$message({
                            type: 'success',
                            message: res.mesg,
                        })
                        this.$emit('close');
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data
                        })
                    }
                }).catch(err => {
                    this.$message({
                        type: 'error',
                        message: err
                    })
                })                
            }else {
                let param = {
                    id: this.id,
                    lineId: this.contactsForm.lineId,
                    username: this.contactsForm.username,
                    phoneNumber: this.contactsForm.phoneNumber,
                    remark: this.contactsForm.remark
                }
                updateContacts(param).then(res => {
                    if(res.code == '000000') {
                        this.$message({
                            type: 'success',
                            message: res.mesg,
                        })
                        this.$emit('close');
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data
                        })
                    }
                }).catch(err => {
                    this.$message({
                        type: 'error',
                        message: err
                    })
                })   
            }

        },
    },

    mounted() {
        this.initForm();
        console.log(this.lineGroup);
    },

    watch: {
        row: function(newValue, oldValue)  {
            this.initForm();
        }
    }
}
</script>

<style lang="scss" scoped>
    .contactsContainer {
        width: 70%;
        margin: 0 auto !important;

        /deep/ .el-form-item {
            display: flex;
            .el-form-item__label {
                width: 15% !important;
            }
            .el-form-item__content {
                flex: 1 !important;
                margin-left: .1172rem !important;
            }
        }
        

        /deep/ .btnGroup {
            .el-form-item__content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
</style>